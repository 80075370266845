// envVariablesConfig.js
const backendUrl = process.env.VUE_APP_PORTAL_BACKEND_URL;
if (!backendUrl) {
  console.error('Error: VUE_APP_PORTAL_BACKEND_URL is not defined in the .env file.');
  throw new Error('Missing VUE_APP_PORTAL_BACKEND_URL in environment variables.');
}

const keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;
if (!keycloakUrl) {
  console.error('Error: VUE_APP_KEYCLOAK_URL is not defined in the .env file.');
  throw new Error('Missing VUE_APP_KEYCLOAK_URL in environment variables.');
}

export { backendUrl, keycloakUrl };
