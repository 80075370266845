<template>
    <div class="status-icon">
        <span :class="getStatusIconClass(status)" class="status-circle"></span>
    </div>
</template>

<script>
export default {
    props: {
        status: String,  // Accept 'status' prop from parent
    },
    methods: {
        getStatusIconClass(status) {
            switch (status) {
                case 'active':
                    return 'active-status';  // Green for active
                case 'maintenance':
                    return 'maintenance-status';  // Orange for maintenance
                case 'error':
                    return 'error-status';  // Red for error
                case 'inactive':
                    return 'inactive-status';  // Gray for inactive
                default:
                    return 'inactive-status';  // Default to inactive
            }
        }
    }
};
</script>

<style scoped>
.status-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* This makes it a circle */
    display: inline-block;
}

/* Colors for different statuses */
.active-status {
    background-color: #2ecc71;
    /* Green for active */
}

.maintenance-status {
    background-color: #f39c12;
    /* Orange for maintenance */
}

.error-status {
    background-color: #e74c3c;
    /* Red for error */
}

.inactive-status {
    background-color: #95a5a6;
    /* Gray for inactive */
}
</style>