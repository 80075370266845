<template>
  <div>
    <router-view /> <!-- Renders the current route's component -->
  </div>
</template>

<script>
import keycloak from '../keycloak';
export default {
  mounted() {
    // Register the event listener when the component is mounted
    window.addEventListener('beforeunload', () => {
      keycloak.logout();
    });
  },
  beforeUnmount() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('beforeunload', () => {
      keycloak.logout();
    });
  }
};
</script>
