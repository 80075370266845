<template>
  <div class="error-section">
    <table v-if="errors.length > 0" class="log-table">
      <thead>
        <tr>
          <th>Error Code</th>
          <th>Error Description</th>
          <th>Help URL</th>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="errorEvent in errors" :key="errorEvent.device_time">
          <td>{{ errorEvent.error_code }}</td>
          <td>{{ errorEvent.error_description }}</td>
          <td><a :href="errorEvent.error_help_link" target="_blank">Help</a></td>
          <td>{{ formatLocalDate(errorEvent.device_date_time) }}</td>
          <td>{{ formatLocalTime(errorEvent.device_date_time) }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>No errors found for this device.</p>
  </div>
</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak'; // Reuse the initialized Keycloak instance
import axios from 'axios';
export default {
  props: {
    deviceId: String
  },
  data() {
    return {
      intervalId:null,
      errors: [] // Store error logs from API
    }
  },
  async mounted() {
    await this.fetchErrorForDevice();
    this.intervalId = setInterval(this.fetchErrorForDevice, 1000);
  },
  beforeUnmount(){
    clearInterval(this.intervalId);
  },
  methods: {
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    async fetchErrorForDevice() {
      try {
        // Update Keycloak token
        await keycloak.updateToken(30);
        const token = keycloak.token;

        // Check if the token is available
        if (!token) {
          this.showError(`Authorization token is missing. Please log in.`);
          return;
        }

        const response = await axios.get(
          `${backendUrl}/api/maintenance/error`,
          {
            params: { id: this.deviceId }, // Pass deviceId as a query parameter
            headers: { Authorization: `Bearer ${token}` } // Include token in the headers
          });

        // Check if the request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Validate response data
        if (!response.data || !response.data.data) {
          this.showError(`Invalid response structure.`);
          return;
        }

        if (!response.data.success) {
          this.showError(`Response returned with error: ${response.data.error}`);
          return;
        }

        // Store the fetched logs in the component's state
        this.errors = response.data.data; // Assuming logs is a data property in your component
      } catch (error) {
        console.error(`fetchLogsForDevice: ${error}`); // Log the error for debugging
        this.showError(`Internal error fetching data about device logs. Please try again.`);
      }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString();
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString();
    }
    //#endregion

  }
};
</script>

<style scoped>
.log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.log-table th,
.log-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.log-table th {
  background-color: #e74c3c;
  color: white;
}

.log-table tr:hover {
  background-color: #f1f1f1;
}

.log-table td {
  color: #333;
}

.log-section p {
  color: #7f8c8d;
  font-style: italic;
}
</style>