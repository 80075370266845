<template>
    <div v-if="keycloak.authenticated">
        <div>
            <section aria-labelledby="device-form-heading" class="device-form-section">
                <div class="form-container">
                    <!-- Input field and refresh button wrapper -->
                    <div class="input-refresh-wrapper">
                        <input v-model="filterString" class="modern-input" type="text"
                            :placeholder="$t('device_data.search_filter')" maxlength="50" />
                        <button @click="refreshList" class="refresh-btn">
                            <i class="fas fa-sync-alt"></i> <!-- Font Awesome icon -->
                        </button>
                    </div>

                    <!-- Pagination buttons -->
                    <div class="pagination-container">
                        <button class="pagination-button" @click="previousPageClick" :disabled="pageIndex === 0">
                            <i class="fas fa-angle-left"></i>
                        </button>
                        <button class="pagination-button" @click="nextPageClick"
                            :disabled="pageIndex === (lastIndexPage - 1)">
                            <i class="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </div>

        <div>
            <div v-if="filteredDeviceData.length === 0">
                {{ $t('device_data.no_devices_found') }}
            </div>

            <div v-else>
                <table class="table">
                    <thead>
                        <tr>
                            <th class="status-column">{{ $t('device_data.status') }}</th>
                            <th class="serial-number-column">{{ $t('device_data.serial_number') }}</th>
                            <th class="mac-address-column">{{ $t('device_data.mac_address') }}</th>
                            <th class="firmware-version-column">Firmware version</th>
                            <th class="name-column">Device title</th>
                            <th class="owner-data-column">{{ $t('device_data.owner_data') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="device in filteredDeviceData" :key="device.device_id"
                            @click="viewDeviceDetails(device)" class="clickable-row">
                            <td class="status-column">
                                <StatusIcon :status="device.status" />
                            </td>
                            <td class="serial-number-column">{{ device.device_serial_number }}</td>
                            <td class="mac-address-column">{{ device.device_mac_address }}</td>
                            <td class="firmware-version-column">{{ device.device_sw_version }}</td>
                            <td class="name-column">{{ device.device_description }}</td>
                            <td class="owner-data-column">{{ device.owner_full_contact }} {{ device.owner_surname }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div v-else>
        <p>{{ $t('general_errors.not_logged_in') }}</p>
    </div>
</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';
import StatusIcon from '@/components/StatusIcon.vue'

export default {
    components: {
        StatusIcon
    },
    async mounted() {
        this.resetPageIndex();
        this.fetchDevicesWithOwnersData();
    },
    data() {
        return {
            keycloak: keycloak,
            listOfDevices: [],   // Store all the devices
            filterString: '',
            ROWS_PER_PAGE: 10,
            pageIndex: 0,
            lastIndexPage: 0
        }
    },
    computed: {
        filteredDeviceData() {
            if (!this.filterString) {
                // If there's no search input, return the full list paginated
                return this.paginatedDevices(this.listOfDevices);
            }

            const searchLower = this.filterString.toLowerCase();

            // Filter listOfDevices based on whether any attribute contains the search term
            const filteredData = this.listOfDevices.filter(device => {
                return (
                    device.device_mac_address.toLowerCase().includes(searchLower) ||
                    device.device_serial_number.toLowerCase().includes(searchLower) ||
                    device.device_description.toLowerCase().includes(searchLower) ||
                    device.owner_name.toLowerCase().includes(searchLower) ||
                    device.owner_surname.toLowerCase().includes(searchLower) ||
                    device.owner_email.toLowerCase().includes(searchLower)
                );
            });

            return this.paginatedDevices(filteredData);
        }
    },
    watch: {
        // Watch for changes in the filtered data to reset the page index
        filterString() {
            // Reset pageIndex whenever filtered data changes
            this.resetPageIndex();
        }
    },
    methods: {
        resetPageIndex() {
            this.pageIndex = 0;
        },
        paginatedDevices(deviceList) {
            // Calculate the number of pages based on the filtered device list
            this.lastIndexPage = Math.ceil(deviceList.length / this.ROWS_PER_PAGE);

            const start = this.pageIndex * this.ROWS_PER_PAGE;
            const end = start + this.ROWS_PER_PAGE;

            // Return only the devices for the current page
            return deviceList.slice(start, end);
        },
        previousPageClick() {
            if (this.pageIndex > 0) {
                this.pageIndex--;
            }
        },
        nextPageClick() {
            if (this.pageIndex < this.lastIndexPage - 1) {
                this.pageIndex++;
            }
        },
        viewDeviceDetails(device) {
            // Emit an event to notify parent (MainPage.vue) about the selected device
            this.$emit('device-selected', device);
        },
        /// Emit events that trigger top level functions
        showError(message) {
            this.$emit('showError', message);
        },
        showSuccess(message) {
            this.$emit('showSuccess', message);
        },
        async refreshList() {
            this.fetchDevicesWithOwnersData();
            this.resetPageIndex();
        },

        /**
         * Fetch device and owner data based on the device ID.
         *
         * @returns {Promise<void>} A promise that resolves when the data is fetched.
         * @throws {Error} Throws an error if the device ID is invalid or if fetching fails.
         */
        async fetchDevicesWithOwnersData() {
            try {
                await keycloak.updateToken(30);
                const token = keycloak.token;
                if (!token) {
                    this.showError(this.$t('general_errors.not_logged_in'));
                    return;
                }

                const response = await axios.get(
                    `${backendUrl}/api/maintenance/deviceswithownerdata`,
                    {
                        params: {},
                        headers: { Authorization: `Bearer ${token}` },
                        validateStatus: function (status) {
                            return status >= 200 && status < 500;
                        }
                    });

                // check is request returned any error
                if (response.status !== 200) {
                    this.showError(response.data.error);
                    return;
                }

                // validate response data
                if (!response.data || !response.data.data) {
                    this.showError(this.$t('general_errors.response_invalid_structure'));
                    return;
                }
                if (!response.data.success) {
                    this.showError(this.$t('general_errors.response_with_error'));
                    return;
                }

                this.listOfDevices = response.data.data;
                return;

            } catch (error) {
                console.error(`fetchDevicesWithOwnersData: ${error}`);
                this.showError(this.$t('general_errors.response_internal_error'));
                return;
            }
        },
    }
}
</script>

<style scoped>
/* General table and form section layout */
.table {
    width: 100%;
    /* Make the table take up the full width of the container */
    table-layout: fixed;
    /* Ensures consistent column widths */
    border-collapse: collapse;
    /* Collapse borders for a clean table look */
    margin: 0 auto;
}

th {
    background-color: #d2d2d2;
    text-align: center;
    /* Center align the table headers */
}

th,
td {
    padding: 10px;
    border: 1px solid #0f85c9;
    text-align: center;
    white-space: nowrap;
    /* Prevent text wrapping inside table cells */
}

.status-column {
    width: 5%;
    /* Status column takes 5% of the total table width */
}

.serial-number-column {
    width: 20%;
    /* Serial number column takes 20% */
}

.mac-address-column {
    width: 25%;
    /* MAC address column takes 25% */
}

.firmware-version-column {
    width: 15%;
    /* Firmware version column takes 15% */
}

.name-column {
    width: 15%;
    /* Name column takes 15% */
}

.owner-data-column {
    width: 20%;
    /* Owner data column takes 20% */
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: #f0f0f0;
}

/* Status column styling */
.status-column {
    width: 5%;
    /* Adjusted to a smaller percentage width */
    text-align: center;
}

/* Responsive table */
@media (max-width: 768px) {

    th,
    td {
        font-size: 12px;
        /* Reduce the font size on smaller screens */
        padding: 8px;
        /* Less padding for smaller screens */
    }
}
.device-form-section {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
}

/* Flex container for input, refresh, and pagination */
.form-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Input and refresh button aligned next to each other */
.input-refresh-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow this container to take full width of the container */
}

.modern-input {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.refresh-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}

.refresh-btn i {
    pointer-events: none;
}

.refresh-btn:hover i {
    color: #007bff;
}

/* Pagination styling */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between the pagination buttons */
}

.pagination-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.pagination-button i {
    font-size: 18px;
}

.pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #0056b3;
}

/* Adjustments for small screens */
@media (max-width: 768px) {
    .pagination-button {
        padding: 8px 10px;
        font-size: 14px;
    }

    .modern-input {
        font-size: 14px;
        padding: 8px;
    }
}
</style>