<template>
  <div v-if="keycloak.authenticated">
    <div class="dashboard">
      <header class="dashboard-header">
        <div class="user-info">
          <div class="dropdown">
            <button class="dropbtn"><span :class="['fi', `fi-${selectedLanguage.flagCode}`]"></span></button>
            <!-- Dropdown content with flags -->
            <div class="dropdown-content">
              <a v-for="flag in flags" :key="flag.name" @click="setLanguage(flag)"><span :class="['fi', `fi-${flag.flagCode}`]"></span></a>
            </div>
          </div>
          <span class="user-name">{{ userData.firstName }}</span>
          <button @click="logout" class="logout-button">{{ $t('menu.logout_text') }}</button>
        </div>
      </header>

      <main class="dashboard-content">
        <div>
          <MessageAlert ref="messageAlert" />
        </div>
        <div>
          <MaintenanceDeviceData v-if="!isDeviceDetailsView" @device-selected="showDeviceDetails" @showError="showError" @showSuccess="showSuccess" />
          <MaintenanceDeviceInfo v-else :device="selectedDevice" @back="goBackToDeviceList" @showError="showError" @showSuccess="showSuccess" />
        </div>
      </main>
    </div>
  </div>
  <div v-else>
    <p>{{ $t('general_errors.not_logged_in') }}</p>
  </div>
</template>

<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';  // Reuse the initialized Keycloak instance
import axios from 'axios';
import MessageAlert from '@/components/MessageAlert.vue';
import MaintenanceDeviceData from '@/components/MaintenanceDeviceData.vue';
import MaintenanceDeviceInfo from '@/components/MaintenanceDeviceInfo.vue';

export default {
  components: {
    MaintenanceDeviceData,
    MaintenanceDeviceInfo,
    MessageAlert
  },
  data() {
    return {
      // Language support
      flags: {
        "en": { name: 'English', flagCode: 'gb', language_code: 'en' },
        "si": { name: 'Slovenian', flagCode: 'si', language_code: 'si' }
      },
      selectedLanguage: { name: 'English', flagCode: 'gb', language_code: 'en' },

      // Keycloak data
      keycloak: keycloak,

      userData: {
        userID: '',
        firstName: '',
        familyName: '',
        email: '',
        language: ''
      },
      isDeviceDetailsView: false,
      selectedDevice: null,
    };
  },
  mounted() {
    this.checkOrAddProfile();
  },
  methods: {
    showDeviceDetails(device) {
      // Switch to DeviceDetails view and set selected device ID
      this.isDeviceDetailsView = true;
      this.selectedDevice = device;
    },
    goBackToDeviceList() {
      // Switch back to MaintenanceDeviceData view
      this.isDeviceDetailsView = false;
      this.selectedDevice = null;
    },
    showError(message) {
      this.$refs.messageAlert.error.show_message = true;
      this.$refs.messageAlert.error.message = message;

      // Hide the error after 3 seconds
      setTimeout(() => {
        this.$refs.messageAlert.error.show_message = false;
      }, 3000);
    },
    showSuccess(message) {
      this.$refs.messageAlert.success.show_message = true;
      this.$refs.messageAlert.success.message = message;

      // Hide the success after 3 seconds
      setTimeout(() => {
        this.$refs.messageAlert.success.show_message = false;
      }, 3000);
    },
    setLanguage(newLanguage) {
      this.selectedLanguage = newLanguage;
      this.$i18n.locale = newLanguage.language_code;  // Use the language code, not the entire object
      this.updateProfileLanguage(newLanguage.language_code);
    },
    logout() {
      keycloak.logout();  // Keycloak handles logout
    },

    /**
    * Login or create user
    *
    */
    async checkOrAddProfile() {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token;
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/profile/create`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // check is request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }
        // validate response data
        if (!response.data || !response.data.data) {
          this.showError(this.$t('general_errors.response_invalid_structure'));
          return;
        }
        if (!response.data.success) {
          this.showError(this.$t('general_errors.response_with_error'));
          return;
        }

        this.userData = response.data.data;
        // Ensure you're accessing the correct data path for language in the response
        this.setLanguage(this.flags[response.data.data.language]);
        this.showSuccess(`${this.$t('menu.hello_text')} ${this.userData.firstName}`);

        return;
      }
      catch (error) {
        console.error(`fetchDeviceAndOwnerData: ${error}`);
        this.showError(this.$t('general_errors.response_internal_error'));
        return;
      }
    },

    /**
    * Updates the user's profile language.
    *
    * @param {string} langCode - The new language code to set for the user's profile.
    */
    async updateProfileLanguage(langCode) {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token;
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        if (!this.userData.userID) {
          this.showError(this.$('profile_data.user_not_found'));
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/profile/updatelanguage`,
          {
            id: this.userData.userID,
            language: langCode
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        // check is request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error || this.$t('profile_data.failed_lang_update'));
          return;
        }
        // validate response data
        if (!response.data || typeof response.data.success === 'undefined') {
          this.showError(this.$t('general_errors.response_invalid_structure'));
          return;
        }
        if (!response.data.success) {
          this.showError(`${this.$t('profile_data.failed_lang_update')}: ${response.data.error || this.$t('general_errors.unknown_error')}`);
          return;
        }

        this.showSuccess(`${this.$t('profile_data.success_lang_update')}: ${langCode}`);

      } catch (error) {
        console.error(`fetchDeviceAndOwnerData: ${error}`);
        this.showError(this.$t('general_errors.response_internal_error'));
      }
    }
  }
};
</script>

<style scoped>
/* Header styling */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #6494ec;
  border-bottom: 1px solid #ddd;
}

/* Button menu and user info styling */
.button-menu {
  display: flex;
  gap: 15px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-name {
  font-weight: bold;
  color: #2c3e50;
  margin-right: 15px;
}

.logout-button {
  padding: 8px 15px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.logout-button:hover {
  background-color: #27ae60;
}

/* Language Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.dropbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 40px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  padding: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropbtn .fi {
  margin-right: 10px;
}

.dropdown-content .fi {
  font-size: 18px;
}
</style>
