<template>
  <div>
    <!-- Error Message Popup -->
    <transition name="fade">
      <div v-if="error.show_message" class="message-popup error-message">
        <p>{{ error.message }}</p>
      </div>
    </transition>

    <!-- Success Message Popup -->
    <transition name="fade">
      <div v-if="success.show_message" class="message-popup success-message">
        <p>{{ success.message }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: {
        show_message: false,
        message: ''
      },
      success: {
        show_message: false,
        message: ''
      }
    };
  }
};
</script>

<style scoped>
/* Pop-up container */
.message-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 15px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: fit-content;
}

/* Error message styling */
.error-message {
  background-color: #e74c3c;
}

/* Success message styling */
.success-message {
  background-color: #2ecc71;
}

/* Fade-in and fade-out animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
