<template>
  <div v-if="keycloak.authenticated">
    <div>
      <!-- Wrap both the back button and the tab buttons in a flex container -->
      <div class="tab-header">
        <button class="back-button" @click="$emit('back')">
          <i class="fas fa-chevron-left"></i>
          {{ $t('device_data.back_to_device_list') }}
        </button>

        <div>
          <span><i class="fas fa-address-card"> {{ device.device_mac_address }}</i></span>
        </div>

        <!-- Tab buttons -->
        <div class="tab">
          <button class="tablinks" :class="{ activeLog: activeTab === 'Logs' }" @click="activeTab = 'Logs'"><i
              class="fas fa-file-alt"> Logs</i></button>
          <button class="tablinks" :class="{ activeError: activeTab === 'Errors' }" @click="activeTab = 'Errors'"><i
              class="fas fa-exclamation-triangle"> Errors</i></button>
          <button class="tablinks" :class="{ activeRepair: activeTab === 'Repair' }" @click="activeTab = 'Repair'"><i
              class="fas fa-tools"> Maintenance Data</i></button>
          <button class="tablinks" :class="{ activeSettings: activeTab === 'Settings' }"
            @click="activeTab = 'Settings'"><i class="fas fa-cog"> Settings</i></button>
            
          <div>
            <!-- Firmware Update Button -->
            <button @click="triggerFWOTAUpdateCallback(device.device_mac_address)" v-if="updateAvailable"
              class="icon-button firmware" title="Update firmware">
              <i class="fas fa-sync-alt"></i>
            </button>

            <!-- Disable Maintenance Mode Button -->
            <button v-if="isInMaintenanceMode(device.device_mac_address)"
              @click="disableMaintenanceMode(device.device_mac_address)" class="icon-button maintenance disable"
              title="Disable Maintenance Mode">
              <i class="fas fa-wrench"></i>
            </button>

            <!-- Enable Maintenance Mode Button -->
            <button v-if="!isInMaintenanceMode(device.device_mac_address)"
              @click="enableMaintenanceMode(device.device_mac_address)" class="icon-button maintenance enable"
              title="Enable Maintenance Mode">
              <i class="fas fa-wrench"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Tab content -->
      <div v-if="activeTab === 'Logs'" class="tabcontent">
        <MaintenanceDeviceLogs :deviceId="device.device_id" @showError="showError" @showSuccess="showSuccess" />
      </div>

      <div v-if="activeTab === 'Errors'" class="tabcontent">
        <MaintenanceDeviceErrors :deviceId="device.device_id" @showError="showError" @showSuccess="showSuccess" />
      </div>

      <div v-if="activeTab === 'Repair'" class="tabcontent">
        <MaintenanceDeviceRepairData :deviceId="device.device_id" :isInMaintenanceMode="isInMaintenanceMode(device.device_mac_address)" @showError="showError" @showSuccess="showSuccess" />
      </div>

      <div v-if="activeTab === 'Settings'" class="tabcontent">
        <MaintenanceDeviceSettingsData :deviceId="device.device_id" :macAddress="device.device_mac_address"
          @showError="showError" @showSuccess="showSuccess" />
      </div>
    </div>
  </div>

  <div v-else>
    <p>{{ $t('general_errors.not_logged_in') }}</p>
  </div>
</template>


<script>
import keycloak from '../keycloak';
import { backendUrl } from '../config/envVariablesConfig';
import axios from 'axios';
import MaintenanceDeviceLogs from './MaintenanceDeviceLogs.vue';
import MaintenanceDeviceErrors from './MaintenanceDeviceErrors.vue';
import MaintenanceDeviceRepairData from './MaintenanceDeviceRepairData.vue';
import MaintenanceDeviceSettingsData from './MaintenanceDeviceSettingsData.vue'

export default {
  components: {
    MaintenanceDeviceLogs,
    MaintenanceDeviceErrors,
    MaintenanceDeviceRepairData,
    MaintenanceDeviceSettingsData
  },
  data() {
    return {
      keycloak: keycloak,
      updateAvailable: true,
      devicesSetToMaintenanceMode: {}, // Store device_ids set to maintenance mode
      activeTab: 'Logs' // Default tab when component mounts
    };
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    // Event that will trigger when users closes tab to put device back to regular mode
    async handleBeforeUnload(event) {
      await this.setAllDevicesBackToRegularMode();
      event.returnValue = ''; // Some browsers show a dialog if returnValue is set
    },
    async triggerFWOTAUpdateCallback(mac_address) {
      const confirmed = window.confirm(this.$t('confirm_ota'));
      // If the user confirms the removal
      if (confirmed) {
        try {
          // Call the async removeDevice function
          await this.triggerFirmwareUpdate(mac_address);
        } catch (error) {
          console.error('Error updating device:', error);
          alert(this.$t('device_data.failed_update_device'));
        }
      }
    },
    /**
     * Send device a message to trigger update
     *
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if the device ID is invalid or if fetching fails.
     */
    async triggerFirmwareUpdate(mac_address) {
      try {

        await keycloak.updateToken(30);
        const token = keycloak.token;
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/otaupdate`,
          { mac: mac_address },
          { headers: { Authorization: `Bearer ${token}` } });


        // check is request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Return the fetched logs data
        this.showSuccess(this.$t('device_data.update_triggered'));
      } catch (error) {
        console.error(`triggerFirmwareUpdate: ${error}`);
        this.showError(this.$t('general_errors.response_internal_error'));
      }
    },
    //#region Change maintenance mode        
    isInMaintenanceMode(mac_address) {
      // Check if the mac_address is in the devicesSetToMaintenanceMode object
      return this.devicesSetToMaintenanceMode[mac_address] === true;
    },

    async enableMaintenanceMode(mac_address) {
      // Directly set the mac_address to true in the devicesSetToMaintenanceMode object
      this.devicesSetToMaintenanceMode[mac_address] = true;
      await this.changeMaintenanceMode(mac_address, true); // Change to maintenance mode
    },

    async disableMaintenanceMode(mac_address) {
      // Directly delete the mac_address from the devicesSetToMaintenanceMode object
      delete this.devicesSetToMaintenanceMode[mac_address];
      await this.changeMaintenanceMode(mac_address, false); // Change out of maintenance mode
    },
    async setAllDevicesBackToRegularMode() {
      // on page close if any of devices are left in repair mode, this will reset them back to regular mode
      for (const mac_address in this.devicesSetToMaintenanceMode) {
        console.info(`Set device ${mac_address} back to regular data`);
        await this.changeMaintenanceMode(mac_address, false);
      }
    },
    /**
     * Change device mode to test/maintenance
     *
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if the device ID is invalid or if fetching fails.
     */
    async changeMaintenanceMode(mac_address, maintenanceMode) {
      try {
        await keycloak.updateToken(30);
        const token = keycloak.token;
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        const response = await axios.post(
          `${backendUrl}/api/maintenance/maintenancemode`,
          {
            mac: mac_address,
            repair_mode: maintenanceMode
          },
          { headers: { Authorization: `Bearer ${token}` } });

        console.log(JSON.stringify(response));
        // check is request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }
      } catch (error) {
        console.error(`changeMaintenanceMode: ${error}`);
        //this.showError(this.$t('general_errors.response_internal_error'));
      }
    }
    //#endregion
  }
};
</script>

<style scoped>
.tab-header {
  display: flex;
  /* Flexbox to align items in a row */
  align-items: center;
  /* Vertically center the buttons */
  justify-content: space-between;
  /* Optional: distribute space evenly */
  gap: 20px;
  width: 90%;
  margin: 20px auto;
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button i {
  margin-right: 8px;
  /* Spacing between icon and text */
}

.back-button:hover {
  background-color: #2980b9;
}

.back-button:focus {
  outline: none;
}

.back-button:active {
  background-color: #1c6e8e;
}

.tab {
  display: flex;
  gap: 10px;
  /* Space between the tab buttons */
}

.tablinks {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #f1f1f1;
  border-bottom: 2px solid transparent;
}

.tablinks.activeLog {
  border-bottom: 2px solid #3498db;
  font-weight: bold;
}

.tablinks.activeError {
  border-bottom: 2px solid #e74c3c;
  font-weight: bold;
}

.tablinks.activeRepair {
  border-bottom: 2px solid #ffa500;
  font-weight: bold;
}

.tablinks.activeSettings {
  border-bottom: 2px solid #179b0b;
  font-weight: bold;
}

.tabcontent {
  width: 90%;
  display: block;
  margin: 20px auto;
}

/* Button styling */
.icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #179b0b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 20px;
    margin: 5px;
    transition: background-color 0.3s ease;
}

/* Hover effect for buttons */
.icon-button:hover {
    background-color: #14810a;
}

/* Maintenance button specific styles */
.icon-button.maintenance.enable {
    background-color: orange;
}

.icon-button.maintenance.disable {
    background-color: red;
}

/* Hover effect for maintenance buttons */
.icon-button.maintenance.enable:hover {
    background-color: darkorange;
}

.icon-button.maintenance.disable:hover {
    background-color: darkred;
}
</style>