<template>
  <div class="log-section">
    <!-- Logs Table -->
    <table v-if="logs.length > 0" class="log-table">
      <thead>
        <tr>
          <th>FW main controller</th>
          <th>FW weight electronics</th>
          <th>Refernce height</th>
          <th>System unis</th>
          <th>{{ $t('general.date') }}</th>
          <th>{{ $t('general.time') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in logs" :key="log.device_time">
          <td>{{ log.device_fw_main_controller }}</td>
          <td>{{ log.device_fw_weight_electronics }}</td>
          <td>{{ log.device_ref_height }}</td>
          <td>{{ log.device_system_units === 0 ? 'METRIC' : log.device_system_units === 1 ? 'IMPERIAL' : 'UNKNOWN' }}</td>
          <td>{{ formatLocalDate(log.device_date_time) }}</td>
          <td>{{ formatLocalTime(log.device_date_time) }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>{{ $t('device_data.no_logs_found') }}</p>
  </div>
</template>
<script>
import { backendUrl } from '../config/envVariablesConfig';
import keycloak from '../keycloak';
import axios from 'axios';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      intervalId:null,
      logs: [] // Store logs fetched from API
    };
  },
  async mounted() {
    await this.fetchLogsForDevice();
    this.intervalId = setInterval(this.fetchLogsForDevice, 1000);
  },
  beforeUnmount(){
    clearInterval(this.intervalId);
  },
  methods: {
    /// Emit events that trigger top level functions
    showError(message) {
      this.$emit('showError', message);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    
    /**
     * Fetches logs for a specified device from the backend API.
     *
     * @returns {Promise<void>} A promise that resolves when the logs are successfully fetched and stored.
     */
    async fetchLogsForDevice() {
      try {
        // Update Keycloak token
        await keycloak.updateToken(30);
        const token = keycloak.token;

        // Check if the token is available
        if (!token) {
          this.showError(this.$t('general_errors.not_logged_in'));
          return;
        }

        // Make an API call to fetch logs
        const response = await axios.get(
          `${backendUrl}/api/maintenance/logs`,
          {
            params: { id: this.deviceId }, // Pass deviceId as a query parameter
            headers: { Authorization: `Bearer ${token}` } // Include token in the headers
          }
        );

        // Check if the request returned any error
        if (response.status !== 200) {
          this.showError(response.data.error);
          return;
        }

        // Validate response data
        if (!response.data || !response.data.data) {
          this.showError(this.$t('general_errors.response_invalid_structure'));
          return;
        }

        if (!response.data.success) {
          this.showError(this.$t('general_errors.response_with_error'));
          return;
        }

        // Store the fetched logs in the component's state
        this.logs = response.data.data; // Assuming logs is a data property in your component
      } catch (error) {
        console.error(`fetchLogsForDevice: ${error}`); // Log the error for debugging
        this.showError(this.$t('general_errors.response_internal_error'));
      }
    },
    formatLocalDate(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleDateString();
    },
    formatLocalTime(utcDateTime) {
      const date = new Date(utcDateTime);
      return date.toLocaleTimeString();
    }
  }
};
</script>

<style scoped>
.log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.log-table th,
.log-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.log-table th {
  background-color: #3498db;
  color: white;
}

.log-table tr:hover {
  background-color: #f1f1f1;
}

.log-table td {
  color: #333;
}

.log-section p {
  color: #7f8c8d;
  font-style: italic;
}
</style>