import { keycloakUrl } from '@/config/envVariablesConfig'; 
import Keycloak from 'keycloak-js';

// Create Keycloak instance
const keycloak = new Keycloak({
  url: keycloakUrl,
  realm: 'unior-maintenance',
  clientId: 'unior-maintenance-frontend',
});

// Initialize Keycloak (for use throughout the app)
export async function initializeKeycloak() {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'login-required', // Force the user to log in before accessing the app
      pkceMethod: 'S256',
      checkLoginIframe: false // Optionally disable iframe session checking for simplicity
    });
    return authenticated;
  } catch (error) {
    console.error('Failed to initialize Keycloak:', error);
    return false;
  }
}

export default keycloak;
