import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import en from './i18n/en.json';
import si from './i18n/si.json';
import App from './views/App.vue';
import router from './router';
import { initializeKeycloak } from './keycloak';  // Adjust the path if needed
import '@fortawesome/fontawesome-free/css/all.css';
import 'flag-icons/css/flag-icons.min.css';

const i18n = createI18n({ // Define your i18n setup
  locale: 'en',           // Set the default locale
  fallbackLocale: 'si',   // Fallback if translation is missing
  messages: {
    en: en,
    si: si,
  }
});


initializeKeycloak().then(() => {
  const app = createApp(App);
  app.use(router);
  app.use(i18n);
  app.mount('#app');
}).catch(error => {
  console.error(`Keycloak initialization failed: ${error}`);
  alert(this.$t('keycloak.failed_to_init'));
});
